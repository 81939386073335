import { setPayOnline, getOrderHistoryByKey } from '@services/order-status'
import { navigate } from 'gatsby'
import { getOrder } from '@helpers/checkout/global'
import { getFromBrowserStorage, saveLocalStorage } from '@helpers/storage'
import { getURLParam } from '@helpers/general'

const deliveryTimeframeOptions = {
  EXPRESS:
    'Your delivery has an all day delivery window (7am - 10pm). We are unable to establish a specific delivery window because Express Delivery requires such a quick turnaround.',
  STANDARD:
    'Specific time frame information is not yet available. Please check back 48 hours prior for your 4 hour delivery window.',
}

export const createPaymentObject = ({ order, creditcardPayment, financePayment, premiumDelivery, DeliveryCharge }) => {
  let paymentObject = {}
  if (order) {
    const {
      DeliveryAddress,
      BillingAddress,
      OrderNumber,
      StoreNumber,
      Division,
      Customer,
      orderKey,
      EmailAddress,
    } = order

    const financePlan = getOrder()?.financePlan

    paymentObject = {
      shippingAddress: {
        address1: DeliveryAddress.Address1,
        address2: DeliveryAddress.Address2,
        city: DeliveryAddress.City,
        state: DeliveryAddress.State,
        zipcode: DeliveryAddress.Zip,
        countryCode: 'us',
      },
      billingAddress: BillingAddress.Address1
        ? {
            address1: BillingAddress.Address1,
            address2: BillingAddress.Address2,
            city: BillingAddress.City,
            state: BillingAddress.State,
            zipcode: BillingAddress.Zip,
            countryCode: 'us',
          }
        : {
            address1: DeliveryAddress.Address1,
            address2: DeliveryAddress.Address2,
            city: DeliveryAddress.City,
            state: DeliveryAddress.State,
            zipcode: DeliveryAddress.Zip,
            countryCode: 'us',
          },
      orderId: OrderNumber,
      storeNumber: StoreNumber,
      division: Division,
      customer: {
        firstName: Customer.FirstName,
        lastName: Customer.LastName,
        email: order.EmailAddress || window.sessionStorage.getItem('orderEmail'),
        phoneNumber: Customer.PhoneNumber.replace(/[^0-9]/g, ''),
      },
      cartToken: orderKey,
    }

    if (financePayment?.authAmount) {
      paymentObject.financePayment = financePayment
      if (!paymentObject.financePayment.uvFinancePlan && financePlan?.code)
        paymentObject.financePayment.uvFinancePlan = financePlan.code?.split('|')[0]
    }

    if (creditcardPayment?.authAmount) {
      paymentObject.creditcardPayment = {
        token: creditcardPayment.token.token,
        authAmount: Number(creditcardPayment.authAmount),
        expMonthYear: creditcardPayment.expMonthYear,
      }
    }

    if (premiumDelivery) {
      paymentObject.deliveryUpgrade = {
        deliveryCharge: DeliveryCharge.deliveryCharge,
        totalTax: DeliveryCharge.total,
      }
    }
  }
  return paymentObject
}

export const submitOrderPayment = (paymentObject, setLoading) => {
  setLoading(true)
  return setPayOnline(paymentObject).then(
    data =>
      new Promise((resolve, reject) => {
        setLoading(false)
        // save current payment to localStorage so thank you page has reference when no redux data is present
        saveLocalStorage('activePayment', paymentObject?.financePayment || paymentObject?.creditcardPayment)
        if (data?.auth === 'success') {
          // TODO: navigation need to add code to track ga data
          navigate('/order/thankyou')
          resolve()
        } else {
          reject(new Error(`Unable to process payment: ${data}`))
        }
      }),
  )
}

export const getOrderOnRefresh = ({ dispatch, setOrder, setOrderList, setOrderNumber }) => {
  let orderKey = getFromBrowserStorage('session', 'orderKey')
  const orderNumber = getFromBrowserStorage('session', 'orderNumber')

  if (typeof orderKey === 'object') {
    if (getURLParam('orderKey') !== null) {
      orderKey = getURLParam('orderKey')
    }
  }

  getOrderHistoryByKey(orderKey).then(data => {
    let order = {}
    let orderList
    if (data) {
      orderList = [data]
      if (typeof orderNumber !== 'object') {
        const newOrder = orderList.filter(item => item.OrderNumber.toString() === orderNumber.toString())[0]
        order = newOrder
      } else {
        order = data
      }
    }
    dispatch(setOrder(order))
    dispatch(setOrderList(orderList))
    dispatch(setOrderNumber(order?.OrderNumber))
  })
}

export const generateDeliveryTimeframe = (estimatedDeliveryTimeFrame = undefined, deliveryType) => {
  let generatedDeliveryTimeframe = ''
  if (estimatedDeliveryTimeFrame?.length === 0) {
    switch (deliveryType) {
      case 'D' || 'K' || 'C' || 'X': // STANDARD - ADULT:
        generatedDeliveryTimeframe = deliveryTimeframeOptions.STANDARD
        break
      case 'E': // EXPRESS:
        generatedDeliveryTimeframe = deliveryTimeframeOptions.EXPRESS
        break
      default:
        generatedDeliveryTimeframe = deliveryTimeframeOptions.STANDARD
        break
    }
    return generatedDeliveryTimeframe
  }
  return estimatedDeliveryTimeFrame
}

export const isOnlineOrder = storeNumber => {
  const newStoreNumber = Number(storeNumber)
  return !!(newStoreNumber === 970 || newStoreNumber === 980 || newStoreNumber === 990)
}
