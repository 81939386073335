import React from 'react'
import { objectOf, any } from 'prop-types'
import styled from 'styled-components'
import GenesisLogo from '@shared/svgs/genesis-logo'
import MaskedCredit from '../checkout/masked-credit'

const PaymentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: ${({ numberOfChildren }) => (numberOfChildren > 3 ? '45%' : '100%')};
`

const PaymentsWrapper = styled.section`
  display: ${({ numberOfChildren }) => (numberOfChildren > 3 ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`

const PaymentSuccessCards = ({ paymentInfo }) => {
  let contents = []
  paymentInfo.forEach(payment => {
    if (payment.authorizedAmount && typeof payment.authorizedAmount === 'number') {
      payment.authorizedAmount = payment.authorizedAmount.toFixed(2)
    }

    switch (payment.paymentType) {
      case 'CYBERV3':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Credit Card</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'GEN':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <GenesisLogo />
            <MaskedCredit lastFour={payment.paymentProperties.accountNumber.slice(12)} />
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'FIN':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Rooms To Go Finance</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'DBUY':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Rooms To Go Finance</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'GIFT':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Gift Card</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'PALV2':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>PayPal</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'AFF':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Affirm</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'VISA':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Visa Checkout</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      case 'KLN':
        contents.push(
          <PaymentWrapper numberOfChildren={paymentInfo.length}>
            <p>Klarna</p>
            <p>${payment.authorizedAmount}</p>
          </PaymentWrapper>,
        )
        break
      default:
        contents = null
    }
  })

  if (contents !== null) {
    return <PaymentsWrapper numberOfChildren={contents.length}>{contents.map(content => content)}</PaymentsWrapper>
  }
  return null
}

PaymentSuccessCards.propTypes = {
  paymentInfo: objectOf(any),
}

export default PaymentSuccessCards
